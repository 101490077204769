import InputAddress from "src/components/form/InputAddress"
import common from "./common"

export default [
  common.email,
  common.name,
  common.gender,
  { extra: { widget: InputAddress } },
  common.phone,
  common.alt_phone,
  {
    name: "foreigner_portuguese",
    label: "fields.foreigner_portuguese.label",
    options: [
      "fields.foreigner_portuguese.onlyPort",
      "fields.foreigner_portuguese.notPort",
      "fields.foreigner_portuguese.all",
    ],
  },
]
