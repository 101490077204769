import React from "react"
import PropTypes from "prop-types"

import FormSubscribe from "src/components/FormSubscribe"
import Layout from "src/components/Layout"
import i18n, { changeLangIfNeeded } from "src/i18n"
import s from "./form.module.scss"

const Page = ({ pageContext: { cursinho, lang } }) => {
  changeLangIfNeeded(i18n, lang)
  return (
    <Layout className={s.main}>
      <FormSubscribe cursinho={cursinho} />
    </Layout>
  )
}

Page.propTypes = {
  pageContext: PropTypes.object,
}

export default Page
