import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form, yupToFormErrors, validateYupSchema } from "formik"
import Loader from "react-loader-spinner"
import { useTranslation, Trans } from "react-i18next"

import "src/i18n"
import { submit } from "src/evag"
import Button from "./Button"
import ModalError from "./ModalError"
import fieldsPt from "src/fields/subscribe.pt"
import fieldsOther from "src/fields/subscribe.other"
import SEO from "src/components/SEO"
import siteMetadata from "src/queries/siteMetadata"
import FieldsToComps, {
  fieldsToSchema,
  fieldsToInitVals,
} from "src/components/FieldsToComps"
import s from "./FormSubscribe.module.scss"

const fields = {
  pt: {
    components: fieldsPt,
    initialValues: fieldsToInitVals(fieldsPt),
    validationSchema: fieldsToSchema(fieldsPt),
  },
  other: {
    components: fieldsOther,
    initialValues: fieldsToInitVals(fieldsOther),
    validationSchema: fieldsToSchema(fieldsOther),
  },
}

function FormSubscribe({
  cursinho: { name, slug, place, address, time, coordination, facebook },
}) {
  const { channelPt, channelOther } = siteMetadata()
  const [submitted, setSubmitted] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const { t, i18n } = useTranslation()

  let fieldsType = i18n.language === "pt" ? "pt" : "other"
  const channel = fieldsType === "pt" ? channelPt : channelOther

  function validate(values) {
    try {
      validateYupSchema(values, fields[fieldsType].validationSchema, true, {
        values,
      })
      return {}
    } catch (err) {
      return yupToFormErrors(err)
    }
  }

  const dismissError = () => setSubmitted(false)

  function onSubmit(values, { setFieldError, setSubmitting }) {
    values.cursinho = slug
    values.subscribe = true
    values.language = i18n.language
    submit(channel, values)
      .then(() => {
        setSubmitted(true)
      })
      .catch((error) => {
        if (error.response && typeof error.response.data === "object") {
          for (let name in error.response.data) {
            if (!error.response.data.hasOwnProperty(name)) {
              continue
            }
            setFieldError(name, error.response.data[name])
          }
          return
        } else if (error.response) {
          // Server returned error.
          console.error(error.response)
        } else if (error.request) {
          // No response received.
          console.error(error.request)
        } else {
          // Something triggered an error when setting the request.
          console.error(error.message)
        }

        setErrorMsg(t("submitError"))
      })
      .then(() => {
        setSubmitting(false)
      })
  }

  const renderForm = () => (
    <>
      <SEO title={name} />

      <h1>{name}</h1>

      <p>
        <i>({t("onlyStudents")})</i>
      </p>

      <p>
        <Trans i18nKey="formIntro" />
      </p>
      <ul>
        <li>{t("itsFree")}</li>
        <li>
          <Trans i18nKey="firstDay" />
        </li>
        {place || address ? (
          <li>{t("classAddress", { place, address })}</li>
        ) : null}
        {time ? <li>{t("classTime", { time })}</li> : null}
        {coordination ? (
          <li>
            {t("classCoordination")}
            {": "}
            {coordination
              .map(({ name, phone }) => `${name}: ${phone}`)
              .join(", ")}
          </li>
        ) : null}
        {facebook ? (
          <li>
            <a href={facebook}>Facebook</a>
          </li>
        ) : null}
      </ul>

      <p>
        <Trans i18nKey="formIntroEnd" />
      </p>

      <Formik
        initialValues={fields[fieldsType].initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ values, handleBlur, handleChange, isSubmitting, setFieldValue }) =>
          isSubmitting ? (
            <Loader
              type="BallTriangle"
              color="#FFFFFF"
              height={50}
              width={50}
            />
          ) : (
            <Form
              className={s.form}
              action={`https://api.evag.io/channels/${channel}/submit`}
              method="post"
            >
              <FieldsToComps
                formName="inscricao"
                {...{
                  fields: fields[fieldsType].components,
                  values,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }}
              />

              <input type="hidden" name="subscribe" value="true" />
              <input type="hidden" name="cursinho" value={slug} />

              <Button type="submit" fontSize="16px" width="100%">
                {t("send")}
              </Button>
              <ModalError error={errorMsg} dismiss={dismissError} />
            </Form>
          )
        }
      </Formik>
    </>
  )

  return (
    <div className="wrap">
      {submitted ? (
        <React.Fragment>
          <p>
            <Trans i18nKey="submitSuccess" />
          </p>
        </React.Fragment>
      ) : (
        <>{renderForm()}</>
      )}
    </div>
  )
}

FormSubscribe.propTypes = {
  cursinho: PropTypes.object.isRequired,
}

export default FormSubscribe
